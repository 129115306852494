export const debounce = (fn, ms = 0) => {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export const dateISO = (date, separator) => {
  return date.toLocaleDateString('en-GB').split('/').reverse().join('-')
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-wizard',{staticClass:"steps-transparent mb-3",attrs:{"title":"Formulario AVC-04","subtitle":"Datos de afiliación y reingreso del trabajador","finish-button-text":"Guardar","back-button-text":"Atrás","next-button-text":"Siguiente"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"1. Empleador","icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Codigo Patronal"}},[_c('validation-provider',{attrs:{"name":"Codigo Patronal","rules":"minmax:12,13|required|regex:^([0-9-]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"00-000-00000000"},on:{"change":function($event){return _vm.SendEmployer_code(true)}},model:{value:(_vm.formData.employer_code),callback:function ($$v) {_vm.$set(_vm.formData, "employer_code", $$v)},expression:"formData.employer_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre o razon social del Empleador","disabled":""}},[_c('validation-provider',{attrs:{"name":"Empleador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"size":"lg"},model:{value:(_vm.formData.employer_name),callback:function ($$v) {_vm.$set(_vm.formData, "employer_name", $$v)},expression:"formData.employer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha de Afiliación"}},[_c('b-form-datepicker',{attrs:{"id":"affiliation_date","locale":"es","show-decade-nav":true,"date-format-options":{day: 'numeric', month: 'numeric', year: 'numeric' },"menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.formData.affiliation_date),callback:function ($$v) {_vm.$set(_vm.formData, "affiliation_date", $$v)},expression:"formData.affiliation_date"}})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"2. Centro de Salud","icon":"feather icon-file-text","before-change":_vm.validationForm2}},[_c('validation-observer',{ref:"accountRules2",attrs:{"tag":"form"}},[_c('MedicalUnit',{ref:"refMedicalUnit"})],1)],1),_c('tab-content',{attrs:{"title":"3. Datos Personales","icon":"feather icon-file-text","before-change":_vm.validationForm3}},[_c('validation-observer',{ref:"accountRules3",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Carnet"}},[_c('validation-provider',{attrs:{"rules":"minmax:5,12|required|regex:^([0-9a-zA-Z-]+)$","name":"Carnet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"00000000-xx"},model:{value:(_vm.formData.ci),callback:function ($$v) {_vm.$set(_vm.formData, "ci", $$v)},expression:"formData.ci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Expedido en"}},[_c('validation-provider',{attrs:{"name":"expedido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options.sigla},model:{value:(_vm.formData.exp),callback:function ($$v) {_vm.$set(_vm.formData, "exp", $$v)},expression:"formData.exp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Número de Asegurado o Matricula"}},[_c('validation-provider',{attrs:{"name":"Matricula","rules":"minmax:9,10|required|regex:^([0-9a-zñA-ZÑ-]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"000000ABC"},model:{value:(_vm.formData.code_affiliate),callback:function ($$v) {_vm.$set(_vm.formData, "code_affiliate", $$v)},expression:"formData.code_affiliate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha de Nacimiento"}},[_c('b-form-datepicker',{attrs:{"id":"birth_date","locale":"es","show-decade-nav":true,"date-format-options":{day: 'numeric', month: 'numeric', year: 'numeric' },"menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.formData.birth_date),callback:function ($$v) {_vm.$set(_vm.formData, "birth_date", $$v)},expression:"formData.birth_date"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Nombres"}},[_c('validation-provider',{attrs:{"name":"Nombres","rules":"minmax:2,40|required|regex:^([A-ZÑ ]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"state":errors.length ? false : null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Paterno"}},[_c('validation-provider',{attrs:{"name":"Paterno","rules":"minmax:2,40|required|regex:^([A-ZÑ ]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"state":errors.length ? false : null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.paternal_surname),callback:function ($$v) {_vm.$set(_vm.formData, "paternal_surname", $$v)},expression:"formData.paternal_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Materno"}},[_c('validation-provider',{attrs:{"name":"Materno","rules":"minmax:2,40|required|regex:^([A-ZÑ ]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"state":errors.length ? false : null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.maternal_surname),callback:function ($$v) {_vm.$set(_vm.formData, "maternal_surname", $$v)},expression:"formData.maternal_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Género","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Género"}},[_c('b-form-select',{attrs:{"options":_vm.options.gender},model:{value:(_vm.formData.gender),callback:function ($$v) {_vm.$set(_vm.formData, "gender", $$v)},expression:"formData.gender"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Celular"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"minmax:8,9|required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "phone_number", $$v)},expression:"formData.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Correo electronico personal","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"correo@mail.com"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"4. Domicilio y Ocupación","icon":"feather icon-file-text"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":"minmax:4,40|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Zona"}},[_c('validation-provider',{attrs:{"name":"Zona","rules":"minmax:4,40|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.address_zone),callback:function ($$v) {_vm.$set(_vm.formData, "address_zone", $$v)},expression:"formData.address_zone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Dirección"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"minmax:7,40|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_zone","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Ocupación"}},[_c('validation-provider',{attrs:{"name":"Ocupacion","rules":"minmax:5,40|required|regex:^([A-ZÑ ]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"profession","state":errors.length > 0 ? false:null,"onkeyup":"this.value=this.value.toUpperCase();"},model:{value:(_vm.formData.profession),callback:function ($$v) {_vm.$set(_vm.formData, "profession", $$v)},expression:"formData.profession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Salario"}},[_c('validation-provider',{attrs:{"name":"Salario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length ? false : null},model:{value:(_vm.formData.salary),callback:function ($$v) {_vm.$set(_vm.formData, "salary", $$v)},expression:"formData.salary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha de ingreso al trabajo"}},[_c('b-form-datepicker',{attrs:{"id":"employer_date","locale":"es","show-decade-nav":true,"date-format-options":{day: 'numeric', month: 'numeric', year: 'numeric' },"menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.formData.employer_date),callback:function ($$v) {_vm.$set(_vm.formData, "employer_date", $$v)},expression:"formData.employer_date"}})],1)],1)],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{attrs:{"body-text-variant":"primary"}},[_vm._v(" NOTA : Debe registrar su información según su AVC-04 de forma exacta según lo que se solicita, el código patronal según el ej. 01-730-00001, si no lo encuentra o tiene algún problema con su registro envíe un mensaje al correo informaciones@cnslpz.bo., en el punto 2. Centro de Salud, busque y seleccione el Centro de Salud y el número de consultorio donde le atienden. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Centro de Salud")])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Centro de Salud Asignado *"}},[_c('validation-provider',{attrs:{"name":"Centro de Salud","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalCenters,"reduce":function (item) { return item.id; },"label":"name","clearable":false},on:{"input":_vm.handleMedicalCenter},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.formData.medical_center_id),callback:function ($$v) {_vm.$set(_vm.formData, "medical_center_id", $$v)},expression:"formData.medical_center_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consultorio *"}},[_c('validation-provider',{attrs:{"name":"Consultorio ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalUnits,"reduce":function (item) { return item.id; },"label":"name"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.formData.medical_unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "medical_unit_id", $$v)},expression:"formData.medical_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }